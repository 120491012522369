import { getColorIndex } from "stores/ThemeProvider";
import { GOOGLE_DRIVE_IMG_PREFIX, ProductProps } from "./Product";
import "common/style/productPreview.css";
import { getFullProductUrl } from "stores/ProductsStore";
import { BlurImg } from "./BlurImg";

export const ProductPreview = (props: ProductProps) => {
  const goToProductPage = () => {
    window.location.href = getFullProductUrl(props);
  };

  const isSoldOut = () => {
    let isSoldOut: boolean = true;

    if (props.variants) {
      props.variants.variantsAndStock.forEach((value) => {
        if (value > 0) isSoldOut = false;
      });
    } else if (props.stock) {
      isSoldOut = props.stock <= 0;
    }

    return isSoldOut;
  };

  const soldOut = isSoldOut();
  const color = soldOut ? "disabled" : `box-color${getColorIndex()}`; // TODO: think if it's annoying for a customer that the color are constantly updating

  const getImage = () => {
    if (props.images) {
      if (props.thumbnail)
        return (
          <BlurImg
            tinySrc={GOOGLE_DRIVE_IMG_PREFIX + props.thumbnail}
            src={GOOGLE_DRIVE_IMG_PREFIX + props.images[0]}
          />
        );

      return <img src={GOOGLE_DRIVE_IMG_PREFIX + props.images[0]} />;
    } else {
      return <div></div>;
    }
  };

  return (
    <div
      className={`product-preview doodly-big-box float-center ${color}`}
      onClick={goToProductPage}
    >
      <h3>{props.title}</h3>
      <h4>{props.subtitle}</h4>
      {getImage()}
      {/* <img src={GOOGLE_DRIVE_IMG_PREFIX + props.images[0]}/> */}
      {/* <img src={`https://drive.google.com/uc?export=view&id=${props.images[0]}`}/> */}
      <h4>{soldOut ? "Sold Out!" : props.price.toString()}</h4>
    </div>
  );
};
