import { ProductProps } from "components/Product";
import { createContext } from "react";

export const ProductContext = createContext<ProductProps[] | undefined>(undefined);

/**
 * gets the product's name part of the url
 * @param product 
 * @returns 
 */
export const getProductUrl = (product: ProductProps) => {
    var urlText = product.subtitle ? `${product.title}-${product.subtitle}` : product.title;
    const titleForUrl = urlText.toLocaleLowerCase().replaceAll(" ", "-");
    return `/product/${titleForUrl}`;
}

/**
 * get the full product url, including the window.location.origin
 * @param product 
 * @returns 
 */
export const getFullProductUrl = (product: ProductProps) => {
    return `${window.location.origin}${getProductUrl(product)}`;
}