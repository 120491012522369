import "common/style/contact.css"
import { InputField, InputFieldType } from "components/InputField";
import { sendContactForm } from "handlers/EmailHandler";
import { useEffect, useState } from "react";
import { ContactInfo } from "types/ContactInfo";
import illustration from "assets/images/doodles/snail-mail.svg";

export const Contact = () => {
 const [contactInfo] = useState<ContactInfo>({} as ContactInfo);
 const [subject, setSubject] = useState<string>("");
 const [message, setMessage] = useState<string>("");
 const [wasSent, setWasSent] = useState<boolean>();

 useEffect(() => {
   document.title = `Contact | Gilliav`
}, [])

 const isAllInfoFilled = () => {
    return (
        contactInfo.email != "" &&
        contactInfo.firstName != "" &&
        contactInfo.lastName != "" &&
        subject != "" &&
        message != ""
    )
 }

   const sendForm = async () => {
      await sendContactForm(contactInfo, subject, message
      ).then((res) => {
         setWasSent(res);

         if (res == true)
            onFormSubmitted();
      }).catch(() => {
         setWasSent(false)
      });
   }

 const onFormSubmitted = () => {
   window.location.href = window.location.origin + "/contact-form-submitted";
 }

 const getSendStatus = () => {
    if (wasSent !== undefined && wasSent === false) {
            return <p>There was an error sending the message. Please try again.</p>
    }
 }

 return (
    <div className="container contact">
      <div>
       <h1>Contact</h1>
       <h4>
          Got a question? Want to commission a custom piece of art?
          Just want to chat? Reach out!
       </h4>
       <div className="contact-form form">
          <div className="joint-form-fields">
             <InputField {...({ label: "First name", className: "form-field small", onChange: (value) => { contactInfo.firstName = value } })} />
             <InputField {...({ label: "Last name", className: "form-field small", onChange: (value) => { contactInfo.lastName = value } })} />
          </div>
          <InputField {...({ label: "Email", className: "form-field large", type: InputFieldType.Email, onChange: (value) => { contactInfo.email = value } })} />
          <InputField {...({ label: "Subject", className: "form-field large", onChange: (value) => { setSubject(value) } })} />
       </div>
      <img className="illustration" src={illustration}/>
       <InputField {...({ label: "Message", className: "form-field extra-large", type: InputFieldType.LongText, onChange: (value) => { setMessage(value) } })} />
       <button className={isAllInfoFilled() ? "primary" : "disabled"} onClick={() => sendForm()}>Send</button>
       {getSendStatus()}
       </div>
       {/* <img className="illustration" src={illustration} /> */}
    </div>
 )
} 