import "common/style/popup.css"

export interface PopupProps {
    content: any,
    handleClose: () => void,
    backgroundColor?: string
}

export const Popup = (props: PopupProps) => {
  const style = {
    backgroundColor: props.backgroundColor ?? undefined
  }

  return (
    <div className="popup-box">
      <div className="box" style={style}>
        <span className="close-icon" onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};