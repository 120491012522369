import { PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useRef } from "react";
import { Price } from "types/Price";
import { Payer, Address } from "@paypal/paypal-js/types/apis/orders"

// TODO: add onPaymentError
export interface PaymentButtonProps {
  total: Price,
  onPaymentApproved: (orderID: string, payerID: string) => void,
  disabled : boolean
}

export const Paypal = (props: PaymentButtonProps) => {
    const totalRef = useRef(props.total);
    const onPaymentApprovedRef = useRef(props.onPaymentApproved);

    useEffect(() => {
      totalRef.current = props.total;
    }, [props.total])

    useEffect(() => {
      onPaymentApprovedRef.current = props.onPaymentApproved;
    }, [props.onPaymentApproved])

    const createOrder = async (data: any, actions: any) => {
      return actions.order.create({
        purchase_units: [
          {
            description: "GILLIAV SHOP",
            amount: {
              currency_code: totalRef.current.currency.code,
              value: totalRef.current.amount,
            }
          }
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING"
        }
      });
    };

    // TODO: figure out if the money is being recieve, change the tester's account to the IL one
    const onApprove = async (data: any, paypalActions: any) => {
      return paypalActions.order.capture().then((details : any) => {
          const paymentData = {
            payerID: data.payerID,
            orderID: data.orderID
          };

          onPaymentApprovedRef.current(paymentData.orderID, paymentData.payerID);
      });
    };

    return (
      <PayPalButtons disabled={props.disabled} style={{layout: "horizontal", color: "black", tagline:false, shape: "pill"}}
                     onError={(err) => console.log(err)}
                     onCancel={(data, action) => console.log(data)}
                     createOrder={(data: any, actions: any) => createOrder(data, actions)}
                     onApprove={(data: any, actions: any) => onApprove(data, actions)}/>
    )
}