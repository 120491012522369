import "../common/style/index.css";
import "../common/style/home.css";
import { useEffect, useState } from "react";
import { ScreenModeContext } from "../stores/ScreenModeStore";
import { ScreenMode } from "../types/ScreenMode";
import { useContext } from "react";
import { Menu } from "components/Menu";

// images
import portrait from "../assets/images/Portrait.png";
import boxHorizontal from "../assets/images/Box.svg";
import boxVertical from "../assets/images/BoxVert.svg";

var Scroll = require("react-scroll");

const CONTENT: string =
  "My name is Gilli " +
  "& I'm an artist " +
  "and software developer " +
  "from Haifa, Israel.";

export const Home = (props?: { jumpToMenu?: boolean; jumpToTop?: boolean }) => {
  const screenMode = useContext(ScreenModeContext);
  const scroll = Scroll.animateScroll;
  const scrollOptions = {
    smooth: "easeInOutQuad",
  };

  const getBgByScreenSize = () => {
    if (screenMode === ScreenMode.Mobile) return boxVertical;
    return boxHorizontal;
  };

  const [bg, setBg] = useState(getBgByScreenSize);

  useEffect(() => {
    document.title = "Gilliav";
  }, []);

  // TODO: on scroll up remove nav link being active
  useEffect(() => {
    if (props?.jumpToMenu === true) {
      scrollToMenu();
    }
  }, [props?.jumpToMenu]);

  useEffect(() => {
    if (props?.jumpToTop === true) {
      scrollToTop();
    }
  }, [props?.jumpToTop]);

  useEffect(() => {
    setBg(getBgByScreenSize());
  }, [screenMode]);

  const scrollToMenu = () => {
    if (window.scrollY < window.innerHeight)
      scroll.scrollToBottom(scrollOptions);
  };

  const scrollToTop = () => {
    if (window.scrollY > 0) scroll.scrollToTop(scrollOptions);
  };

  return (
    <div className="home">
      <div className="home-box center">
        <h1>Hey!</h1>
        <h2>{CONTENT}</h2>
        <img src={portrait} className="portrait" />
        <img src={bg} />
      </div>
      <Menu />
    </div>
  );
};
