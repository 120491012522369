import { CartItem, CartItemProps } from "components/CartItem";
import "common/style/cart.css"
import { useEffect, useState } from "react";
import { Price } from "types/Price";
import { InputField, inputFieldProps } from "components/InputField";
import useStore from "global-hook-store";
import { cartStore } from "stores/CartStore";

export const Cart = () => {
    const {
        state,
        actions
    } = useStore(cartStore);

    const getItems = () : JSX.Element[] => {

        // TODO: make item clickable and go to product page on click
        return (state.items.map(item => {
            return (
                <CartItem {...item}/>
            )  
        })) 
    }

    useEffect(() => {
        document.title = "Cart | Gilliav"
        actions.syncCartAcrossTabs();
    }, [])

    // TODO: figure out the margin bottom when there's a scrollbar and height is unset
    return (
        <div className="container">
            {state.items.length == 0 ? 
             <h2>Your cart is empty :(</h2> :
             <div className="cart">
                <div className="cart-items">
                    { getItems() }
                </div>
                
                
                <div className="bottom">
                <div className="checkout-area">
                    <div className="shop-details">
                        <h4>Sub-total: {state.total.toString()}</h4>
                    </div>
                    <button className="primary checkout-button"
                            onClick={() => window.location.href = window.location.origin + "/checkout"}>
                        Checkout
                    </button>
                </div>
                </div>
             </div>}
        </div>
    )
}