import React from 'react';
import ReactDOM from 'react-dom';
import './common/style/index.css';
import App from './App';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDHdv5wGP0VFdwgDMC5Q4Eg9RJlYpDfE7Q",
  authDomain: "gilliav-web.firebaseapp.com",
  projectId: "gilliav-web",
  storageBucket: "gilliav-web.appspot.com",
  messagingSenderId: "412040679143",
  appId: "1:412040679143:web:0a74e33df6d6d9dda8dc42",
  measurementId: "G-4XKN14CDQP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);