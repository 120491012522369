import "common/style/inputField.css"
import "common/style/index.css"
import { useState } from "react";

export enum InputFieldType {
    Text = "text",
    Email = "email",
    Phone = "tel",
    LongText = "textarea"
}

export interface inputFieldProps<T> {
    label: string,
    onChange?: (value: T) => void,
    className?: string,
    type?: InputFieldType
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const TEL_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

// TODO: make labels permanent after fill
export const InputField = (props: inputFieldProps<string>) => {
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const validateInput = (input: string) => {
        if (props.type == InputFieldType.Email) {
            return EMAIL_REGEX.test(input);
        } else if (props.type == InputFieldType.Phone) {
            return TEL_REGEX.test(input);
        }

        return true;
    }
    
    const onInputChanged = (input: string) => {
        if (validateInput(input)) {
            
            if (isInvalid)
                setIsInvalid(false)
            
            if (props.onChange)
                props.onChange(input);
        } else {
           setIsInvalid(true);
        }
    }

    return (
        <div className={props.className}>
            {props.type == InputFieldType.LongText
            ? 
             <textarea placeholder={props.label}
                       onChange={e => onInputChanged(e.target.value)}
                       required={true} rows={6}/>
            :
                <input type={props.type} placeholder={props.label}
                       onChange={e => onInputChanged(e.target.value)}/>}
            <label>{props.label}</label>
            {isInvalid ?
            <p className="invalid-input-message">Please enter a valid {props.label.toLocaleLowerCase()}</p> :
            null}
        </div>
    )
}