import { Info, InfoProps } from "components/Info";
import illustration from "assets/images/snail-cropped.gif"
import "common/style/confirmation.css"
const title = "Contact"
const subtitle = "Contact Form Sumbitted";

export const ContactFormSubmitted = () => {
    const redirectToContactForm = () => {
        window.location.href = window.location.origin + "/contact";
    }

    const content = <h4>Thank you for you message! {<a onClick={() => redirectToContactForm()}>Want to send another one?</a>}</h4>

    const infoProps: InfoProps = {
        title: title,
        showTitle: false,
        className: "doodly-long-box box-color1",
        subtitle: subtitle,
        content: content
    }

    return (
        <div className="container confirmation-page">
            <Info {...infoProps}/>
            <img src={illustration} className="illustration"/>
        </div>
    )
}