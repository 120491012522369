import { useEffect, useState } from "react"
import { AddressInfo } from "types/Address"
import { InputField, InputFieldType } from "./InputField"

export interface AddressProps {
    onAddressFilled: (addressInfo: AddressInfo) => void
}

export const Address = (props: AddressProps) => {
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [addressLine, setAddressLine] = useState<string>();
    const [addressLine2, setAddressLine2] = useState<string>();
    const [country, setCountry] = useState<string>();
    const [state, setState] = useState<string>();
    const [city, setCity] = useState<string>();
    const [zipcode, setZipcode] = useState<string>();

    // on address updated
    useEffect(() => {
        // if all necessary fields are filled, update parent
        if (addressLine && city && country &&
            firstName && zipcode && lastName) {
                const info : AddressInfo = {
                    firstName: firstName,
                    lastName: lastName,
                    addressLine: addressLine,
                    addressLine2: addressLine2,
                    country: country,
                    state: state,
                    city: city,
                    zipcode: zipcode
                }

                props.onAddressFilled(info);
            }
    }, [firstName, lastName, addressLine2, addressLine, country, state, city, zipcode])
    
    return (
        <>
            <div className="joint-form-fields">
                <InputField {...({label: "First name", className: "form-field small", onChange:(value) => setFirstName(value)})}/>
                <InputField {...({label: "Last name", className: "form-field small", onChange:(value) => setLastName(value)})}/>
            </div>
            <InputField {...({label: "Address line 1", className: "form-field large", onChange:(value) => setAddressLine(value)})}/>
            <InputField {...({label: "Address line 2 (optional)", className: "form-field large", onChange:(value) => setAddressLine2(value)})}/>
            <div className="joint-form-fields">
                <InputField {...({label: "City", className: "form-field small", onChange:(value) => setCity(value)})}/>
                <InputField {...({label: "Zip code", className: "form-field small", onChange:(value) => setZipcode(value)})}/>
            </div>
            <div className="joint-form-fields">
                <InputField {...({label: "Country", className: "form-field small", onChange:(value) => setCountry(value)})}/>
                <InputField {...({label: "State (optional)", className: "form-field small", onChange:(value) => setState(value)})}/>
            </div>
            <InputField {...({label: "Phone (optional)", type: InputFieldType.Phone, className: "form-field large"})}/> 
        </>
    )
}