import { ArtworkProps } from "components/Artwork";
import { ProductProps, ProductVariant } from "components/Product";
import { ArtworkTag } from "components/Tag";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, addDoc, collection, serverTimestamp, updateDoc, increment, getDoc, getDocs, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions, query, where, Timestamp, orderBy} from "firebase/firestore";
import { SHIPPING_METHOD } from "screens/AltCheckout";
import { AddressInfo } from "types/Address";
import { DisplaySize } from "types/ArtworkTypes";
import { ContactInfo } from "types/ContactInfo";
import { OrderInfo, orderItemToJson } from "types/OrderInfo";
import { Currency, Price } from "types/Price";
import { PromoCode, PromoCodeType } from "types/PromoCode";
// TODO: break into different files

const firebaseConfig = {
    apiKey: "AIzaSyDHdv5wGP0VFdwgDMC5Q4Eg9RJlYpDfE7Q",
    authDomain: "gilliav-web.firebaseapp.com",
    projectId: "gilliav-web",
    storageBucket: "gilliav-web.appspot.com",
    messagingSenderId: "412040679143",
    appId: "1:412040679143:web:0a74e33df6d6d9dda8dc42",
    measurementId: "G-4XKN14CDQP"
};

// TODO: add catches and logs!

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// TODO: check why this didn't work in a differet gmail browser
// Adds the order to the db and returns a flag about succesfully being added
export const addOrder = async (
    orderID: string,
    cart: OrderInfo,
    contact: ContactInfo,
    payerID: string,
    shippingMethod?: SHIPPING_METHOD,
    address?: AddressInfo
    ) : Promise<boolean> => {
    let order;
    const time = serverTimestamp();

    try {
        let contactRef = await addContactInfo(contact);
        
        if (shippingMethod === SHIPPING_METHOD.MAIL && address) {
            order = {
                time: time,
                customer: contactRef,
                total: cart.total,
                cart: cart.items.map(item => orderItemToJson(item)),
                shippingMethod: SHIPPING_METHOD[shippingMethod],
                address: await addAddress(address),
                shippingCost: cart.shippingCost,
                promoCode: cart.promo ?? "",
                payerID: payerID,
                isComplete: false
            }
        } else {
            order = {
                time: time,
                customer: contactRef,
                total: cart.total,
                cart: cart.items.map(item => orderItemToJson(item)),
                shippingMethod: shippingMethod !== undefined ?
                                SHIPPING_METHOD[shippingMethod] :
                                "ERROR",
                promoCode: cart.promo ?? "",
                payerID: payerID,
                isComplete: false
            }
        }
        
        const docRef = await setDoc(doc(db, "orders", orderID), order);

        cart.items.forEach(item => {
            updateStock(item.id, item.quantity, item.variant);
        });

        console.log(`order ${orderID} was created in the db`)
        return true;
    }
    catch (error) {
        console.error(`something went wrong when trying to log order ${orderID} ` +
                      `by ${contact.firstName} ${contact.lastName} into the db`);
        console.debug(error)
        return false;
    }
}

const addAddress = async (address: AddressInfo) => {
    let collectoinRef = collection(db, "addresses");

    const docRef = await addDoc(
        collectoinRef, {
            firstName: address.firstName,
            lastName: address.lastName,
            addressLine1: address.addressLine,
            addressLine2: address.addressLine2 ?? "",
            city: address.city,
            country: address.country,
            state: address.state ?? "",
            zipcode: address.zipcode
        }
    );

    return docRef;
}

const addContactInfo = async(info: ContactInfo) => {
    let collectoinRef = collection(db, "customers")

    const docRef = await addDoc(
        collectoinRef, {
            firstName: info.firstName,
            lastName: info.lastName,
            email: info.email
        }
    )

    return docRef;
}

const updateStock = async (productID: string, quantity: number, variant?: string) => {
    const productRef = doc(db, `products/${productID}`);
    const fieldToUpdate = variant ? `stock.${variant.toLowerCase()}` : "stock";

    await updateDoc(productRef, {
        [fieldToUpdate] : increment(-quantity)
    });
}

export const getProducts = async () : Promise<ProductProps[]> => {
    const querySnapshot = await getDocs(collection(db, "products").withConverter(productConverter));
    let products : ProductProps[] = [];

    querySnapshot.forEach(doc => {
       products.push(doc.data()); // converts to local object and adds to array
    })

    return products;
}

/**
 * returns all the promo codes that have a matching code.
 */
export const getPromoCodes = async (code: string) : Promise<PromoCode[]> => {
    const promoCodesRef = collection(db, "promo-codes");
    const promoCodeQuery = query(promoCodesRef, where("code", "==", code));
    const querySnapshot = await getDocs(promoCodeQuery.withConverter(promoCodeConverter));
    let codesData : PromoCode[] = [];

    querySnapshot.forEach((doc) => {
        codesData.push(doc.data());
    })

    return codesData;
}

export const getPortfolio = async() : Promise<ArtworkProps[]> => {
    const q = query(collection(db, "portfolio").withConverter(artworkConverter), orderBy("order"));
    const querySnapshot = await getDocs(q);
    let artworks : ArtworkProps[] = [];

    querySnapshot.forEach(doc => {
       artworks.push(doc.data()); // converts to local object and adds to array
    })

    return artworks;
}

//#region firestore data converters
const artworkConverter : FirestoreDataConverter<ArtworkProps> = {
    toFirestore: (data: ArtworkProps) => {
        return {}
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>,
                    options?: SnapshotOptions | undefined) : ArtworkProps => {
        const data = snapshot.data(options);
        let tags = undefined;

        if (data.tags) {
            tags = (data.tags as string[]).map(value => value as ArtworkTag);
        }

        if (!data.images) {
            console.log(`${snapshot.id} is missing images`);
        }

        return {
            id: snapshot.id,
            images: data.images,
            year: data.year ?? undefined,
            title: data.title ?? undefined,
            text: data.text ?? undefined,
            displaySize: (data.displaySize as DisplaySize) ?? undefined,
            tags: tags,
            thumbnail: data.thumbnail ?? undefined
        }
    }
}

const promoCodeConverter : FirestoreDataConverter<PromoCode> = {
    toFirestore: (data: PromoCode) => {
        return {
            code: data.code,
            isActive: data.isActive,
            startDate: Timestamp.fromDate(data.startDate),
            endDate: Timestamp.fromDate(data.endDate),
            isInterntional: data.isInternational,
            minimum: data.minimum?.toBaseCurrency(),
            value: data.value,
            type: data.type.toString(),
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>,
                    options?: SnapshotOptions | undefined) : PromoCode => {
        const data = snapshot.data(options);

        return {
            code: data.code,
            startDate: (data.startDate as Timestamp).toDate(),
            endDate: (data.endDate as Timestamp).toDate(),
            type: <PromoCodeType>data.type,
            isActive: data.isActive ?? undefined,
            value: data.value ?? undefined,
            minimum: data.minimum ? new Price(data.minimum, Currency.shekel) : undefined,
            isInternational: data.isInternational ?? undefined
        }
    }
}

const productConverter : FirestoreDataConverter<ProductProps> = {
    toFirestore: (product: ProductProps) => {
        return {
            details: product.text,
            images: product.images, // TODO: get the urls
            price: product.price.amount, // TODO: make sure it's in shekels
            quantity: [], // TODO: fill with map
            subtitle: product.subtitle,
            title: product.title,
            thumbnail: product.thumbnail,
            variants: [], // TODO: do an array of variant type of arrays of the variants,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>,
                    options?: SnapshotOptions | undefined) => {
        const data = snapshot.data(options);
        const doVariantsExist = data.variants !== undefined;

        const product : ProductProps = {
            sku: snapshot.id,
            images: data.images,
            thumbnail: data.thumbnail,
            title: data.title,
            price: new Price(data.price, Currency.shekel),
            subtitle: data.subtitle,
            text: data.details,
            variants: doVariantsExist ? getVariants(data) : undefined,
            stock: doVariantsExist ? undefined : data.stock
        }

        return product;
    }
};
//#endregion
//#region private methods
const getVariants = (data: DocumentData) => {
    let variants : ProductVariant = {
        category: "",
        variantsAndStock: new Map<string, number>()
    }

    // go over each variant category
    data.variants.forEach((variant: any) => {
        // add name
        variants.category = capitalizeFirstLetter(variant.name);

        // add the variants for said category
        variant.variants.forEach((variantName: string) => {
            variants.variantsAndStock.set(capitalizeFirstLetter(variantName), data.stock[variantName] ?? 0);
        })
    })

    return variants;
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
//#endregion