/**
 * How big should the artwork be displayed
 * (small is "regular" size, a.k.a 1 by 1).
*/
export enum DisplaySize {
 Small = 0,
 Medium = 1,
 Large = 2
}

export enum DisplayOrientation {
 Square = "square",
 Wide = "wide",
 Tall = "tall",
 Default = "tall"
}