import { ProductProps } from "components/Product";
import { ProductPreview } from "components/ProductPreview";
import { useContext, useEffect } from "react";
import "common/style/shop.css"
import { ProductContext } from "stores/ProductsStore";

export const Shop = () => {
    const products = useContext(ProductContext)

    // TODO: make this istant somwhow, or wait until it is at least
    // TODO: return the "no items" thing once you figure this out

    const getProductsPreviews = () => {
        return products?.map(prod => {
            return <ProductPreview {...prod}/>
        })
    }

    useEffect(() => {
        document.title = "Shop | Gilliav"
    }, [])
    
    // TODO: make the width of the grid not go over the container
    return (
        <div className="container">
            {/* {    */}
                {/* // products ? */}
                <div className="shop-products">
                    {getProductsPreviews()}
                </div>
                {/* // <h2>There are currently no items for sale :(</h2> */}
            {/* } */}
        </div>
    )
}