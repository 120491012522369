import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { ContactInfo } from 'types/ContactInfo';
import { OrderInfo } from 'types/OrderInfo';

// TODO: hide
const USER_ID = "4ARasXITdn_EUvmna";
const ZOHO_USER_SERIVCE = "service_b23gjnq"; // orders@gilliav.com
const GMAIL_USER_SERVICE = "service_impzd0d"; // gilliav.art@gmail.com
const CONFIRMATION_TEMPLATE_ID = "template_rjgo72d";
const CONTACT_TEMPLATE_ID = "template_mlf8nda";
const ALERT_TEMPLATE_ID = "template_npe0v0p";

export interface confirmationEmailParams {
    email: string,
    firstName: string,
    orderId: string,
    orderInfo: OrderInfo 
}

export const sendConfirmationEmail = async (params: confirmationEmailParams) => {
    const orderSummary = getOrderSummary(params.orderInfo);
    
    const template_params = {
        "email": params.email,
        "firstName": params.firstName,
        "orderId": params.orderId.slice(0,6),
        "orderSummary": orderSummary
    };

    await emailjs.send(
        ZOHO_USER_SERIVCE,
        CONFIRMATION_TEMPLATE_ID,
        template_params,
        USER_ID 
    ).then((status : EmailJSResponseStatus) => { 
        if (status.status === 200) {
            console.info("order confirmation email sent successfully")
        } else {
            console.error("failed to send order confirmation email")
            console.debug(status)
        }
    }).catch((error) => {
        console.error("failed to send order confirmation email")
        console.log(error)
    })

    // await sendSellerAlertEmail(params.orderId, orderSummary);
}

export const sendContactForm = async (contactInfo: ContactInfo, subject: string, message: string) : Promise<boolean> => {
    const template_params = {
        "email": contactInfo.email,
        "first_name": contactInfo.firstName,
        "last_name": contactInfo.lastName,
        "subject": subject,
        "message": message
    };
    let result = false;

    await emailjs.send(
        GMAIL_USER_SERVICE,
        CONTACT_TEMPLATE_ID,
        template_params,
        USER_ID 
    ).then((status : EmailJSResponseStatus) => { 
        if (status.status === 200) {
            console.info("order confirmation email sent successfully")
            result = true;
        } else {
            console.error("failed to send order confirmation email")
            console.debug(status)
        }
    }).catch((error) => {
        console.error("failed to send order confirmation email")
        console.log(error)
    }).finally(() => {
        return result;
    });

    return result;
}

// replaced by a BCC on the confirmation email
const sendSellerAlertEmail = async (orderId: string, orderSummary: string) => {
    const template_params = {
        "orderId": orderId,
        "orderSummary": orderSummary
    };

    await emailjs.send(
        GMAIL_USER_SERVICE,
        ALERT_TEMPLATE_ID,
        template_params,
        USER_ID 
    ).then((status : EmailJSResponseStatus) => { 
        if (status.status === 200) {
            console.info("order alert email sent successfully")
        } else {
            console.error("failed to send order confirmation email")
            console.debug(status)
        }
    }).catch((error) => {
        console.error("failed to send order confirmation email")
        console.log(error)
    })
}

const getOrderSummary = (orderInfo: OrderInfo) => {
    let summary : string = "";

    orderInfo.items.forEach(item => {
        summary = summary.concat(`${item.quantity}x${item.variant} ${item.name}, ${item.price}₪\n`)
    })

    return summary
}