import { Price } from "./Price";

export interface PromoCode {
 code: string,
 isActive?: boolean,
 startDate: Date,
 endDate: Date,
 minimum?: Price,
 value?: number,
 type: PromoCodeType,
 isInternational?: boolean
}

export enum PromoCodeType {
 PrecentOff = "PrecentOff",
 AmountOff = "AmountOff",
 FreeShipping = "FreeShipping"
}

/**
 * Possible promo code scenarois, sorted by best case scenario
 * a.k.a sorted so in case there's a few promo codes with the same code,
 * it's clear which one to present
 */
export enum PromoCodeResult {
 Active,
 NotEnoughSpent,
 NotInArea,
 NotActive,
 GeneralError,
 NotFound,
}