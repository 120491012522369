import { useState } from "react";
import { ArtworkProps } from "./Artwork";
import "common/style/artworkDetails.css"

const INIT_ZOOM_SCALE = 1;
const ZOOM_SCALE_CHANGE = 1.5;
const MAX_ZOOM_SCALE = 2.25;

export const ArtworkDetails = (props: ArtworkProps) => {
    const [mainImageIndex, setMainImage] = useState<number>(0);

    // TODO: make this image stuff into a component so we can reuse it in product
    const changeMainImage = (newMainImg: number) => {
        // If it's the first pircture, grab the last one
        if (newMainImg < 0)
            newMainImg = (props.images.length - 1);
        // If it's the last image, grab the first one
        else if (newMainImg >= props.images.length)
            newMainImg = 0;
        
        setMainImage(newMainImg);
        setZoomScale(INIT_ZOOM_SCALE);
    }

    /**
         * disable right click menu to prevent people from downloading the images
         * @param e 
         */
    const preventContextMenu = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
    }

    const [zoomScale, setZoomScale] = useState(INIT_ZOOM_SCALE);

    // Used to zoom the main photo in and out
    const mainImageStyle = {
        transform: `scale(${zoomScale})`,
        transition: "transform ease-out 0.2s"
    }

    const zoomMainImage = () => {
        if (zoomScale < MAX_ZOOM_SCALE)
            setZoomScale(zoomScale * ZOOM_SCALE_CHANGE)
        else
            setZoomScale(INIT_ZOOM_SCALE)
    }
 
    const getImagesView = () => {
        if (props.images.length > 1) {
            return (
                <div className="view">
                    {/* <div className="arrows">
                        <h1 className="left" onClick={() => changeMainImage(mainImageIndex - 1)}>&lt;</h1>
                        <h1 className="right" onClick={() => changeMainImage(mainImageIndex + 1)}>&gt;</h1>
                    </div> */}
                    <div className="main-image">
                    <img src={`https://drive.google.com/uc?export=view&id=${props.images[mainImageIndex]}`} alt="artwork"
                         className="zoomable" onContextMenu={preventContextMenu} style={mainImageStyle} onClick={() => zoomMainImage()}/>
                    </div>
                    <div className="minor-images">
                        {props.images.map((src, index) => {
                            return <img src={`https://drive.google.com/uc?export=view&id=${src}`} alt="artwork" onClick={() => changeMainImage(index)} onContextMenu={preventContextMenu}/> 
                        })}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="main-image">
                    <img src={`https://drive.google.com/uc?export=view&id=${props.images[0]}`} alt="artwork"
                        onContextMenu={preventContextMenu} style={mainImageStyle}
                        onClick={() => zoomMainImage()} className=" zoomable"/>
                </div>
            )
        }
    }

    return (
        <div className="artwork-details">
            {getImagesView()}
            <div className="artwork-text">
                <h3>{props.title}</h3>
                <h4>{props.year}</h4>
            </div>
        </div>
    )

}