import { useProgressiveImg } from "hooks/useProgressiveImg";

export const BlurImg = (props: {tinySrc: string, src: string}) => {
  const { src, blur } = useProgressiveImg(props.tinySrc, props.src);
  return (
    <img
      src={src}
      style={{
        filter: blur ? "blur(16px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
        clipPath: "inset(0 round 8px)"
      }}
    />
  );
};