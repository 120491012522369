import React, { useContext, useEffect, useState } from "react";
import useStore from "global-hook-store";
import { cartStore } from "stores/CartStore";
import { OrderConfirmation } from "./OrderConfirmation";
import { Shop } from "./Shop";
import { getProductUrl, ProductContext } from "stores/ProductsStore";
import { FAQ } from "./FAQ";
import { Contact } from "./Contact";
import { ContactFormSubmitted } from "./ContactFormSubmitted";
import { ScreenModeContext } from "stores/ScreenModeStore";
import { ScreenMode } from "types/ScreenMode";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
} from "react-router-dom";

// style
import "../common/style/nav.css";

import { Portfolio } from "./Portfolio";
import { Home } from "./Home";
import { Cart } from "./Cart";

// images
import logo from "../assets/images/Logo.svg";
import bg from "../assets/images/Nav.svg";
import { Product, ProductProps } from "components/Product";
import { AltCheckout } from "screens/AltCheckout";
import instagramIcon from "../assets/images/instagram.png";
import emailIcon from "../assets/images/email.png";
import linkedinIcon from "../assets/images/linkedin.png";
import shopIcon from "../assets/images/shop-bag.png";

// TODO: fix problem with left nav links move the logo

export const Navbar = () => {
  const { state, actions } = useStore(cartStore);

  const screenMode = useContext(ScreenModeContext);

  const products = useContext(ProductContext);

  const onStorageUpdate = () => {
    actions.syncCartAcrossTabs();
  };

  // handle reload, refresh, new tab etc.
  // TODO: figure out if there's a smoother/more efficent way.
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", onStorageUpdate);
    window.onload = onStorageUpdate;

    return () => {
      window.onload = null;
      window.removeEventListener("DOMContentLoaded", onStorageUpdate);
    };
  }, []);

  const getRoutesForProducts = () => {
    return products?.map((product) => {
      return (
        <Route path={getProductUrl(product)}>
          <Product {...product} />
        </Route>
      );
    });
  };

  const getNavLinks = () => {
    if (screenMode === ScreenMode.Desktop) {
      return (
        <>
          <NavLink to="/">
            <img src={logo} className="nav-logo center" />
          </NavLink>
          <NavLink to="/shop" className="nav-item">
            Shop
          </NavLink>
          <NavLink to="/portfolio" className="nav-item">
            Portfolio
          </NavLink>
          <NavLink to="/contact" className="nav-item">
            Contact
          </NavLink>
        </>
      );
    } else {
      return (
        // TODO
        // <NavLink to="/home" className="nav-item">
        //   Menu
        // </NavLink>
        <NavLink className="nav-item" to={"/menu"}>
          Menu
        </NavLink>
      );
    }
  };

  return (
    <div>
      <img src={bg} className="nav-bg" />
      <div className="router">
        <Router>
          <div className="nav-components">
            <NavLink to="/">
              <img src={logo} className="nav-logo center" />
            </NavLink>
            {getNavLinks()}
            <NavLink className="nav-item last" to="/cart">
              <img className="icon cart-icon" src={shopIcon} />
              <sup>{state.quantity ?? 0}</sup>
            </NavLink>
          </div>
          <Switch>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
            <Route path="/shop">
              <Shop />
            </Route>
            <Route path="/FAQ">
              <FAQ />
            </Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/checkout">
              <AltCheckout />
            </Route>
            <Route path="/order-confirmation">
              <OrderConfirmation />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/contact-form-submitted">
              <ContactFormSubmitted />
            </Route>
            <Route path="/menu">
              <Home {...{ jumpToMenu: true }} />
            </Route>
            {getRoutesForProducts()}
            <Route path="/">
              <Home {...{ jumpToTop: true }} />
            </Route>
          </Switch>
          <div className="footer">
            {/* <h4>© Gilli Avrech 2022</h4> */}
            <div className="icons">
              <Link
                to={{ pathname: "https://instagram.com/gilliav" }}
                target="_blank"
              >
                <img className="icon ig-icon" src={instagramIcon} />
              </Link>
              <Link
                to={{ pathname: "mailto:hello@gilliav.com" }}
                target="_blank"
              >
                <img className="icon email-icon" src={emailIcon} />
              </Link>
              <Link
                to={{
                  pathname: "https://il.linkedin.com/in/gilli-avrech-b09032173",
                }}
                target="_blank"
              >
                <img className="icon linkedin-icon" src={linkedinIcon} />
              </Link>
            </div>
          </div>
        </Router>
      </div>
    </div>
  );
};
