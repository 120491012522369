import { useState } from "react";
import { Filter } from "./Filter";
import "common/style/filterSelect.css";

export const FilterSelect = (props: {
  filters: any[];
  default?: any;
  isMultiSelect?: boolean;
  onChange: (selected: any | any[]) => void;
}) => {
  const [selected, setSelected] = useState(props.default);

  const onFilterAdded = (filter : any) => {
    if (props.isMultiSelect) {
      let newSelected;
      if (!selected) {
        newSelected = [];
      } else {
        newSelected = selected.slice(0); // copy the array
        newSelected.push(filter);
      }

      setSelected(newSelected);
      props.onChange(newSelected);
    } else {
      setSelected(filter);
      props.onChange(filter);
    }
  };

  const onFilterRemoved = (filter : any) => {
    if (props.isMultiSelect) {
        let newSelected = selected.filter((f: any) => {return f !== filter})
        setSelected(newSelected);
        props.onChange(newSelected);
    } else {
      setSelected(null);
      props.onChange(null);
    }
  };

  const getFilters = () => {
    return props.filters.map((filter) => {
      return (
        <Filter
          {...{
            title: filter,
            onSelect: () => onFilterAdded(filter),
            onUnselect: () => onFilterRemoved(filter),
            isSelected: props.isMultiSelect
              ? selected?.includes(filter)
              : selected === filter,
          }}
        ></Filter>
      );
    });
  };

  return <div className="categories">{getFilters()}</div>;
};
