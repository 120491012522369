import { useEffect } from "react";
import "common/style/info.css"

export interface InfoProps {
 title: string,
 showTitle?: boolean,
 subtitle?: string,
 text?: any,
 className?:string,
 content?:any
}


// A generic information component
export const Info = (props: InfoProps) => {
    useEffect(() => {
        document.title = `${[props.title]} | Gilliav`
    }, [])

    return (
        <div className="info-page">
            <div className={props.className}>
                {props.showTitle ? <h1>{props.title}</h1> : <></>}
                <h3>{props.subtitle}</h3>
                <h4>{props.text}</h4>
                {props.content}
            </div>
        </div>
    )
}