export interface OrderItem {
    name: string,
    id: string,
    price: number,
    quantity: number,
    variant: string
}

export interface OrderInfo {
    items: OrderItem[],
    total: number,
    shippingCost: number,
    promo?: string
}

export const orderItemToJson = (item: OrderItem) => {
    return {
        id: item.id, // TODO: make this return a ref to db product instead
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        variant: item.variant
    }
}