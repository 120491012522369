import "common/style/product.css";
import { useEffect, useState } from "react";
import { Price } from "types/Price";
import Dropdown from "react-dropdown";
import { Option } from "react-dropdown";
import "react-dropdown/style.css";
import { CartItemProps } from "./CartItem";
import useStore from "global-hook-store";
import { cartStore } from "stores/CartStore";
import { Popup } from "./Popup";
import "common/style/cartPopup.css";
import { BlurImg } from "./BlurImg";
import { useProgressiveImg } from "hooks/useProgressiveImg";

export interface ProductVariant {
  category: string;
  variantsAndStock: Map<string, number>;
}

export interface ProductProps {
  sku: string;
  images: string[]; // the images' google drive ids
  title: string;
  subtitle?: string;
  thumbnail?: string;
  text?: string;
  price: Price;
  variants?: ProductVariant;
  stock?: number; // for products without variants
}

export const GOOGLE_DRIVE_IMG_PREFIX =
  "https://drive.google.com/uc?export=view&id="; // TODO: relocate
const INIT_ZOOM_SCALE = 1;
const ZOOM_SCALE_CHANGE = 1.5;
const MAX_ZOOM_SCALE = 2.25;

export const Product = (props: ProductProps) => {
  const { actions } = useStore(cartStore);

  const [choosenVariant, setChoosenVariant] = useState<string>();
  const [mainImageIndex, setMainImage] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { src, blur } = useProgressiveImg(
    props.thumbnail
      ? GOOGLE_DRIVE_IMG_PREFIX + props.thumbnail
      : GOOGLE_DRIVE_IMG_PREFIX + props.images?.[0],
    GOOGLE_DRIVE_IMG_PREFIX + props.images?.[0]
  );

  useEffect(() => {
    document.title = `${props.title} ${props.subtitle} | Gilliav`;
  }, []);

  const addItemToCart = () => {
    if (!(props.variants && !isVariantChoosen())) {
      var item: CartItemProps = {
        sku: props.sku,
        price: props.price,
        name: props.subtitle ? `${props.title} ${props.subtitle}` : props.title,
        image: props.images?.[0],
        variantName: props.variants ? props.variants.category : undefined,
        choosenVariant: choosenVariant ?? undefined,
        quantity: 1,
      };

      actions.addItem(item);
      setShowPopup(true);
    }
  };

  const changeMainImage = (newMainImg: number) => {
    // If it's the first pircture, grab the last one
    if (newMainImg < 0) newMainImg = props.images.length - 1;
    // If it's the last image, grab the first one
    else if (newMainImg >= props.images.length) newMainImg = 0;

    setMainImage(newMainImg);
    setZoomScale(INIT_ZOOM_SCALE);
  };

  const getVariants = (): Option[] => {
    let options: Option[] = [];
    let disabledOptions: Option[] = [];

    props.variants?.variantsAndStock.forEach((stock, variant) => {
      let option: Option = {
        label: variant,
        value: variant,
      };

      if (stock > 0) {
        options.push(option);
      } else {
        option.className = "disabled";
        option.value = "";
        disabledOptions.push(option);
      }
    });

    return options.concat(disabledOptions);
  };

  const isVariantChoosen = () => {
    return choosenVariant !== undefined && choosenVariant !== "";
  };

  const cartPopUp = (
    <div className="cart-popup">
      <h3>Item added to cart!</h3>
      <div className="button-group">
        <button
          className="primary popup-cart-btn"
          onClick={() =>
            window.location.assign(window.location.origin + "/cart")
          }
        >
          Go To Cart
        </button>
        <button className="popup-shop-btn" onClick={() => setShowPopup(false)}>
          Continue Shopping
        </button>
      </div>
    </div>
  );

  const getInfoText = () => {
    var x = props.text?.split("<br>")!;

    return x.map((element) => {
      return <p>{element}</p>;
    });
  };

  const [zoomScale, setZoomScale] = useState(INIT_ZOOM_SCALE);

  // Used to zoom the main photo in and out
  const mainImageStyle = {
    transform: `scale(${zoomScale})`,
    transition:
      props.thumbnail && blur
        ? "transform ease-out 0.2s"
        : "filter 0.3s ease-out",
    filter: props.thumbnail && blur ? "blur(16px)" : "none",
    clipPath: "inset(0 round 8px)",
  };

  // TODO: make it zoom on area of click
  // TODO: make into a zoomable component
  const zoomMainImage = () => {
    if (zoomScale < MAX_ZOOM_SCALE) setZoomScale(zoomScale * ZOOM_SCALE_CHANGE);
    else setZoomScale(INIT_ZOOM_SCALE);
  };

  return (
    <div className="container product-container">
      {props.images ? (
        <div className="view">
          <div className="arrows">
            <h1
              className="arrow left"
              onClick={() => changeMainImage(mainImageIndex - 1)}
            >
              &lt;
            </h1>
            <h1
              className="arrow right"
              onClick={() => changeMainImage(mainImageIndex + 1)}
            >
              &gt;
            </h1>
          </div>
          <div className="main-product-image zoomable">
            <img
              src={
                props.thumbnail && blur
                  ? src
                  : GOOGLE_DRIVE_IMG_PREFIX + props.images[mainImageIndex]
              }
              alt="product image"
              onClick={() => zoomMainImage()}
              style={mainImageStyle}
            />
          </div>
          <div className="minor-product-images">
            {props.images?.map((src, index) => {
              return (
                <img
                  loading="lazy"
                  src={`${GOOGLE_DRIVE_IMG_PREFIX}${src}`}
                  alt="another product image"
                  onClick={() => changeMainImage(index)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="info">
        <div className="doodly-big-box basic-info float-center box-color0">
          <h1>{props.title}</h1>
          <h2>{props.subtitle}</h2>
          <div>{getInfoText()}</div>
          <p className="price">{props.price.toString()}</p>
        </div>
        {props.variants ? (
          <div className="doodly-big-box buy-info box-color1">
            {
              <div className="variant-picker">
                <p>{props.variants.category}:</p>
                <Dropdown
                  options={getVariants()}
                  onChange={(value) => {
                    setChoosenVariant(value.value);
                  }}
                />
              </div>
            }
            <button
              className={`primary add-to-cart ${
                isVariantChoosen() ? "" : "disabled"
              }`}
              disabled={!isVariantChoosen()}
              onClick={() => addItemToCart()}
            >
              {choosenVariant === "" ? "Sold Out :(" : "Add To Cart"}
            </button>
          </div>
        ) : (
          <div className="buy-info">
            <button
              className={`primary add-to-cart`}
              disabled={props.stock == 0}
              onClick={() => addItemToCart()}
            >
              {props.stock == 0 ? "Sold Out :(" : "Add To Cart"}
            </button>
          </div>
        )}
        {showPopup ? (
          <Popup content={cartPopUp} handleClose={() => setShowPopup(false)} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
