import "common/style/altCheckout.css"
import { Address, AddressProps } from "components/Address";
import { InputField, InputFieldType } from "components/InputField";
import { Paypal } from "components/Paypal";
import useStore from "global-hook-store";
import { useEffect, useState } from "react";
import 'react-dropdown/style.css';
import { cartStore } from "stores/CartStore";
import { addOrder } from "handlers/DBHandler";
import { confirmationEmailParams, sendConfirmationEmail } from "handlers/EmailHandler";
import { orderStore } from "stores/OrderStore";
import { AddressInfo } from "types/Address";
import { ContactInfo } from "types/ContactInfo";
import { OrderInfo, OrderItem } from "types/OrderInfo";
import { Price } from "types/Price";
import { getPromoCodes } from "handlers/DBHandler";
import { PromoCode, PromoCodeResult, PromoCodeType } from "types/PromoCode";
import { CreditCardPay } from "components/CreditCardPay";

export enum SHIPPING_METHOD {
    PICKUP,
    MAIL
}

const SHIPPING_RATE =  {
    PICKUP: new Price(0),
    NATIONAL: new Price(13),
    INTERNATIONAL: new Price(20)
}

export const AltCheckout = () => {
    const [contactInfo] = useState<ContactInfo>({} as ContactInfo);
    const [isContactInfoFilled, setIsContactInfoFilled] = useState<boolean>(false);
    const [address, setAddress] = useState<AddressInfo>({} as AddressInfo);
    const [isContactCollapsed, setIsContantCollapsed] = useState<boolean>(false);
    const [isPromoCollapsed, setIsPromoCollapsed] = useState<boolean>(true);
    const [isPaymentCollapsed, setIsPaymentCollapsed] = useState<boolean>(true);
    const [isShippingCollapsed, setIsShippingCollapsed] = useState<boolean>(true);
    const [shippingMethod, setShippingMethod] = useState<SHIPPING_METHOD | undefined>();
    const [shippingRate, setShippingRate] = useState<Price | undefined>();
    const [isAddressFilled, setIsAddressFilled] = useState<boolean>(false);
    const [total, setTotal] = useState<Price>()
    const [promoCode, setPromoCode] = useState<string>("")
    const [isAllInfoFilled, setIsAllInfoFilled] = useState<boolean>(false);
    const [discount, setDiscount] = useState<Price | undefined>();
    const [promoCodeMessage, setPromoCodeMessage] = useState<string>("");

    const onAddressFilled = (address: AddressInfo) => {
        setAddress(address);
        setIsAddressFilled(true);

        if (shippingMethod)
            updateShippingRate(shippingMethod)
    }

    useEffect(() => {
        document.title = "Checkout | Gilliav"
        actions.syncCartAcrossTabs();
    }, [])

    useEffect(() => {
        if (shippingMethod)
            updateShippingRate(shippingMethod)
    }, [address.country]);

    const addressProps : AddressProps = {
        onAddressFilled: onAddressFilled
    }

    // TODO: figure out how to use two stores in the same scope.
    // for now, I added the orderID to the cart store.
    const {
        state,
        actions
    } = useStore(cartStore);

    const isShippingNational = () => {
        let country = address.country.trim().toLocaleUpperCase();
        return (country === "ISRAEL" || country === "ישראל");
    }

    const updateShippingRate = (shippingMethod : SHIPPING_METHOD) => {
        if (shippingMethod !== undefined) {
            if (shippingMethod === SHIPPING_METHOD.MAIL && address.country) {
                if (isShippingNational()) // TODO: change country to slider so this will fit
                    setShippingRate(SHIPPING_RATE.NATIONAL);
                else
                    setShippingRate(SHIPPING_RATE.INTERNATIONAL);
            }
            else if (shippingMethod === SHIPPING_METHOD.PICKUP) {
                setShippingRate(SHIPPING_RATE.PICKUP);
            }
            // Fallback, i.e. shipping is selected but no country
            else {
                setShippingRate(undefined);
            }
        }
    }

    const onShippingMethodChanged = (method: SHIPPING_METHOD) => {
        // If it's a double click, cancel the choosen method
        if (shippingMethod === method) {
            setShippingMethod(undefined);
            setShippingRate(undefined)
        }
        else {
            setShippingMethod(method);
        }
    }

    useEffect(() => updateTotal(), [shippingMethod, discount])

    const updateTotal = () => {
        var price = new Price(state.total.amount, state.total.currency);

        if (shippingRate)
            price.add(shippingRate);

        if (discount)
            price.subtract(discount);

        setTotal(price);
    }
    
    const onOrderCompleted = async (orderID: string, payerID: string) => {
        // TODO: figure out if using paypal's order id is safe
        actions.updateOrderID(orderID);
        
        const orderItems : OrderItem[] = state.items.map(item => {
            return {
                name: item.name,
                price: item.price.amount * item.quantity,
                id: item.sku,
                variant: item.choosenVariant ?? "",
                quantity: item.quantity ?? 1,
            }
        }) 

        const cart : OrderInfo = {
            items: orderItems,
            total: total?.amount ?? 0,
            shippingCost: shippingRate?.amount ?? 0,
            promo: promoCode
        }

        if (shippingMethod === undefined) {
            console.error(`Order ${orderID} by ${contactInfo.firstName} ${contactInfo.lastName}` +
                          `has been created, but shipping method is missing.`)
        }

        await addOrder(orderID, cart, contactInfo, payerID, shippingMethod, shippingMethod === SHIPPING_METHOD.MAIL ? address : undefined);

        var confrimationProps: confirmationEmailParams = {
            email: contactInfo.email,
            firstName: contactInfo.firstName,
            orderId: orderID,
            orderInfo: cart
        }

        await sendConfirmationEmail(confrimationProps);

        window.location.assign(window.location.origin + "/order-confirmation");

        actions.emptyCart();
    }

    const updateIsAllInfoFilled = () => {
        if (isContactInfoFilled &&
            (shippingMethod === SHIPPING_METHOD.PICKUP) ||
            (shippingMethod === SHIPPING_METHOD.MAIL &&
             isAddressFilled === true)){
                setIsAllInfoFilled(true);
            }
        else {
            setIsAllInfoFilled(false);
        }
    }

    // on contact info filled, check if all info is filled
    const updateContactInfoFillState = () => {
        console.log("contact changed!")
        if ((contactInfo.email != "" && contactInfo.firstName != "" && contactInfo.lastName != "")) {
            setIsContactInfoFilled(true);
        }
        else {
            setIsContactInfoFilled(false);
        }
    }

    useEffect(() => {
        updateIsAllInfoFilled();
    }, [isContactInfoFilled, shippingMethod, isAddressFilled])

    //#region promo code
    // TODO: check out why this is slow and unrealiable
    const tryApplyPromoCode = async () => {
        // reset discount
        setPromoCodeMessage("")
        setDiscount(undefined)

        var bestMatchedCode : [PromoCode, PromoCodeResult] | undefined = undefined;
        
        // Check for valid promo code
        if (promoCode) {
            var codes = await getPromoCodes(promoCode);
            if (codes.length == 0) {
                updatePromoCodeMessage(PromoCodeResult.NotFound);
            }
            else {
                var goOverAllPromoCodes = new Promise<void>((resolve) => 
                    codes.forEach(async (code, index, array) => {
                        var result = await validatePromoCode(code);

                        // If the code is a better match (a.k.a has a better chance to work)
                        // replace it.
                        if (!bestMatchedCode || result < bestMatchedCode[1]) {
                            bestMatchedCode = [code, result];
                        }

                        if (index === array.length -1)
                            resolve();
                    }   
                ));

                goOverAllPromoCodes.then(() => {
                    if (bestMatchedCode![1] == PromoCodeResult.Active)
                        applyPromoCode(bestMatchedCode![0])
                    else
                        updatePromoCodeMessage(bestMatchedCode![1])
                })
            }
        }
    }

    const updatePromoCodeMessage = (result: PromoCodeResult) => {
        switch(result) {
            case PromoCodeResult.NotActive: {
                setPromoCodeMessage("The code isn't currently active :(")
                break
            }
            case PromoCodeResult.NotEnoughSpent: {
                setPromoCodeMessage("Sorry, you need to spend a little more to use this code!")
                break
            }
            case PromoCodeResult.NotFound: {
                setPromoCodeMessage("This code doesn't seem to exist :(")
                break
            }
            case PromoCodeResult.NotInArea: {
                setPromoCodeMessage("Sorry, this code doesn't apply to your shipping area :(")
                break
            }
            case PromoCodeResult.GeneralError: {
                setPromoCodeMessage("Sorry, there was en error in getting the promo code's details")
                break
            }
            case PromoCodeResult.Active: {
                setPromoCodeMessage("Code activated!")
                break
            }
            default: 
                break
        }
    }

    const applyPromoCode = async (promoCode : PromoCode) => {
        switch (promoCode.type) {
            case PromoCodeType.AmountOff: {
                if (!promoCode.value) {
                    updatePromoCodeMessage(PromoCodeResult.GeneralError)
                } else {
                    setDiscount(new Price(promoCode.value));
                    updatePromoCodeMessage(PromoCodeResult.Active)
                }

                break;
            }
            case PromoCodeType.FreeShipping: {
                if (!shippingMethod || shippingMethod !== SHIPPING_METHOD.MAIL) {
                    setPromoCodeMessage("This code is only avilable for shipping via mail");
                } else if (!shippingRate) {
                    setPromoCodeMessage("Please fill in your entire address and try again")
                } else {
                    setDiscount(shippingRate)
                    updatePromoCodeMessage(PromoCodeResult.Active)
                }

                break;
            }
            case PromoCodeType.PrecentOff: {
                if (!promoCode.value) {
                    updatePromoCodeMessage(PromoCodeResult.GeneralError)
                } else {
                    setDiscount(new Price((1 - promoCode.value / 100) * await state.total.toBaseCurrency()));
                    updatePromoCodeMessage(PromoCodeResult.Active)
                }

                break;
            }
            default: {
                updatePromoCodeMessage(PromoCodeResult.GeneralError)
                break;
            }
        }
    }

    const validatePromoCode = async (promoCode: PromoCode) : Promise<PromoCodeResult> => {
        // If the code isn't in an active state
        if (promoCode.isActive === false) {
            return PromoCodeResult.NotActive;
        }

        const now = new Date();
        
        // TODO: check if works correctly different timezones
        // If the promo codes isn't active according to it's date
        if (!(promoCode.startDate <= now && promoCode.endDate >= now)) {
            return PromoCodeResult.NotActive;
        }

        if (promoCode.minimum) {
            var compare = await state.total.compare(promoCode.minimum);

            if (compare < 0)
                return PromoCodeResult.NotEnoughSpent;
        }

        if (promoCode.isInternational && !isShippingNational())
            return PromoCodeResult.NotInArea;

        return PromoCodeResult.Active;
    }
    //#endregion

    return (
        <div className="container alt-checkout">
            <div className="doodly-long-box box-color2">
                <div className="collapsible-header">
                    <h3>Contact Information</h3>
                    <h6 onClick={() => setIsContantCollapsed(!isContactCollapsed)}
                        className={isContactCollapsed ? "" : "arrow-up"}>
                        &#709;
                    </h6>
                </div>
                <div className={isContactCollapsed ? "collapsible-body collapsed" : "collapsible-body form"}>
                    <InputField {...({label: "First name", className: "form-field large", onChange:(value) => {contactInfo.firstName = value; updateContactInfoFillState();}})}/>
                    <InputField {...({label: "Last name", className: "form-field large", onChange:(value) => {contactInfo.lastName = value; updateContactInfoFillState();}})}/>
                    <InputField {...({label: "Email", className: "form-field large", type: InputFieldType.Email, onChange:(value) => {contactInfo.email = value; updateContactInfoFillState();}})}/>             
                </div>
            </div>
            <div className="doodly-long-box box-color3">
                <div className="collapsible-header">
                    <h3>Promo Code</h3>
                    <h6 onClick={() => setIsPromoCollapsed(!isPromoCollapsed)}
                        className={isPromoCollapsed ? "" : "arrow-up"}>
                        &#709;
                    </h6>
                </div>
                <div className={isPromoCollapsed ? "collapsible-body collapsed" : "collapsible-body"}>
                    <div className="promo-code">   
                        <InputField {...({label: "Promo code", className: "form-field small", onChange:(value) => setPromoCode(value)})}/>
                        <h4>{promoCodeMessage}</h4>
                    </div>
                    <button onClick={tryApplyPromoCode}>
                        Apply
                    </button>
                </div>
            </div>
            <div className="doodly-long-box box-color0">
                <div className="collapsible-header">
                    <h3>Shipping Information</h3>
                    <h6 onClick={() => setIsShippingCollapsed(!isShippingCollapsed)}
                        className={isShippingCollapsed ? "" : "arrow-up"}>
                        &#709;
                    </h6>
                </div>
                <div className={isShippingCollapsed ? "collapsible-body collapsed" : "collapsible-body form"}>
                    <div className="shipment-buttons">
                        <button className={shippingMethod === SHIPPING_METHOD.PICKUP ? "clicked" : ""} 
                                onClick={() => onShippingMethodChanged(SHIPPING_METHOD.PICKUP)}>
                            Pick-up<br/>
                            <h4>from Haifa, Israel</h4>
                        </button>
                        <button className={shippingMethod === SHIPPING_METHOD.MAIL ? "clicked" : ""}
                                onClick={() => onShippingMethodChanged(SHIPPING_METHOD.MAIL)}>
                            Shipping
                        </button>
                    </div>
                    {/* SHIPPING INFO */}
                    <div hidden={shippingMethod !== SHIPPING_METHOD.MAIL} className="address-form">
                        <Address {...addressProps}/>
                    </div>
                    {/* PICKUP INFO */}
                    <div hidden={shippingMethod !== SHIPPING_METHOD.PICKUP}>
                        <h4>We will contact you shortly to schedule a pick-up.</h4>
                    </div>
                </div>
            </div>
            <div className="doodly-long-box box-color1">
                <div className="collapsible-header">
                    <h3>Payment</h3>
                    <h6 onClick={() => setIsPaymentCollapsed(!isPaymentCollapsed)}
                        className={isPaymentCollapsed ? "" : "arrow-up"}>
                        &#709;
                    </h6>
                </div>
                <div className={isPaymentCollapsed ? "collapsible-body collapsed" : "collapsible-body"}>
                    <h4>Subtotal: {state.total.toString()}</h4>
                    <h4>Shipping: {isAllInfoFilled ? (shippingRate ? shippingRate.toString() : "Free") : "Missing details"}</h4>
                    {
                        discount ?
                        <h4>Discount: {discount.toString()}</h4> :
                        <></>
                    }
                    <h4>Total: {isAllInfoFilled ? total?.toString() : "Missing details"} </h4>    
                    <div className="paypal-button">
                    <Paypal total={total ?? new Price(0)}
                            onPaymentApproved={(orderID, payerID) => onOrderCompleted(orderID, payerID)} 
                            disabled={!isAllInfoFilled}/>
                    {/* <CreditCardPay total={total ?? new Price(0)}
                            onPaymentApproved={(orderID, payerID) => onOrderCompleted(orderID, payerID)} 
                            disabled={!isAllInfoFilled}/> */}
                    </div>
                </div>
            </div>
            <div className="bottom-margin"></div>
        </div>
    )
}