import { Image } from "types/Image";
import "common/style/cartItem.css"
import { Price } from "types/Price";
import { getColorIndex } from "stores/ThemeProvider";
import { useEffect, useState } from "react";
import useStore from "global-hook-store";
import { cartStore } from "stores/CartStore";

export interface CartItemProps{
    sku: string,
    name: string,
    image: string,
    quantity: number;
    variantName?: string,
    choosenVariant?: string,
    price: Price // price for a single item
}

export const CartItem = (props: CartItemProps) => {
    const [price, setPrice] = useState<Price>();

    const {
        actions
    } = useStore(cartStore);

    useEffect(() => {
        setPrice(new Price(props.price.amount, props.price.currency));
    }, [props.price])

    return (
        <div className={`cart-item doodly-long-box box-color${getColorIndex()}`}>
            <img src={`https://drive.google.com/uc?export=view&id=${props.image}`}/>
            <div className="item-info float-center">
                <h3>{props.name}</h3>
                <div className="sub-info">
                {props.variantName ? <h4>{props.variantName}: {props.choosenVariant}</h4> : <></>}
                <h4>Quantity: {props.quantity}</h4>
                <h4>Price: {price?.toString()}</h4>
                </div>
            </div>
            <h2 className="delete-button" onClick={() => actions.removeItem(props)}>X</h2>
        </div>
    )
}

