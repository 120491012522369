import "common/style/shopPolicy.css"

export const ShopPolicy = () => {
 return (
  <div className="shop-policy">
   <h1>Shop Policy</h1>
   <h3>Refund</h3>
   <p>
    If you're unhappy with your purchase, returns and exchanges can be made within 21 business days
    from delivery date on all non-commissioned merchandise in its original, unworn and unwashed condition.<br/>
    All commissions and items made to order are non-refundable.<br/>
    If you want to exchange a product or return it - please contact us.
   </p>
   <h3>
    Shipping
   </h3>
   <p>
    Orders are shipped using Israeli Post's registered mail service.
    Pick-up from Bat-Galim, Haifa is also avilable for those intrerested.<br/>
    If you choose to pick up, you'll be contacted with details about location and time.
   </p>
   <h3>
    Payment
   </h3>
   <p>
    Payment is handled through paypal. A paypal account isn't necessary to complete a purchase, and you may pay with a credit card if you choose to do so.<br/>
   </p>
  </div>
 )
}