import React, { DetailedHTMLProps, ImgHTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { ArtworkTag } from "./Tag";
import "common/style/artwork.css"
import { Popup } from "./Popup";
import { ArtworkDetails } from "./ArtworkDetails";
import { useProgressiveImg } from "hooks/useProgressiveImg";
import { GOOGLE_DRIVE_IMG_PREFIX } from "./Product";
import { DisplayOrientation, DisplaySize } from "types/ArtworkTypes";

// thumbnails
import THUMBNAIL_PURPLE from "assets/images/thumbnails/ratio 3x4/thumbnail purple.jpg";
import THUMBNAIL_PINK from "assets/images/thumbnails/ratio 3x4/thumbnail pink.jpg";
import THUMBNAIL_YELLOW from "assets/images/thumbnails/ratio 3x4/thumbnail yellow.jpg";
import THUMBNAIL_ORANGE from "assets/images/thumbnails/ratio 3x4/thumbnail orange.jpg";

const SOLID_THUMBNAILS = [THUMBNAIL_PURPLE, THUMBNAIL_PINK, THUMBNAIL_YELLOW, THUMBNAIL_ORANGE]

export interface ArtworkProps {
    id: string,
    images: string[]
    title?: string
    text?: string
    tags?: ArtworkTag[]
    year?: number,
    displaySize?: DisplaySize, 
    thumbnail?: string,
    loadingStyle?: 'lazy' | 'eager',
    useProvidedThumbnail?: boolean
}

export const Artwork = (props: ArtworkProps) => {
    const [isFullSize, setIsFullSize] = useState<boolean>(false);

    const [displayOrientation, setDisplayOrientation] = useState<DisplayOrientation>(DisplayOrientation.Default);

    const getRandomThumbnail = () => {
        return SOLID_THUMBNAILS[Math.floor(Math.random() * 4)]
    }

    const { src, blur } = useProgressiveImg(props.useProvidedThumbnail && props.thumbnail ? GOOGLE_DRIVE_IMG_PREFIX + props.thumbnail : getRandomThumbnail(), GOOGLE_DRIVE_IMG_PREFIX + props.images[0]);
    let artworkImgElemRef = React.createRef<HTMLImageElement>();

    const getMainImage = () : string => {
        return props.images[0];
    }

    /**
     * disable right click menu to prevent people from downloading the images
     * @param e 
     */
    const preventContextMenu = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
    }

    useEffect(() => {
        calcDisplayOrientation();
    }, [artworkImgElemRef])

    const calcDisplayOrientation = () => {
        if (artworkImgElemRef.current?.naturalWidth) {
            const heightWidthRatio = (artworkImgElemRef.current.naturalHeight / artworkImgElemRef.current.naturalWidth);

            if (heightWidthRatio > 1.15) {
                setDisplayOrientation(DisplayOrientation.Tall);
        } else if (heightWidthRatio < 0.85) {
                setDisplayOrientation(DisplayOrientation.Wide);
            }
        }
    }

    const onImageClicked = () => {
        setIsFullSize(true);
    }

    const getDisplaySize = () => {
        return props.displaySize ? DisplaySize[props.displaySize].toLocaleLowerCase() : "small"
    }

    const onImageLoaded = () => {
        calcDisplayOrientation(); 
    }

    return (
        <div className={`artwork ${displayOrientation} ${getDisplaySize()}`}>
            <img 
            loading={props.loadingStyle ?? 'lazy'}
            src={(blur) ? src : GOOGLE_DRIVE_IMG_PREFIX + getMainImage()}
                 onClick={() => onImageClicked()}
                 onLoad={onImageLoaded}
                 ref={artworkImgElemRef}
                 onContextMenu={preventContextMenu}
                 style={{
                    filter: (props.thumbnail && blur && props.useProvidedThumbnail) ? "blur(16px)" : "none",
                    transition: (blur) ? "none" : "filter 0.3s ease-out",
                    clipPath: "inset(0 round 8px)"
                  }}
                 className="portfolio-preview"
                 />
            {
                isFullSize ?
                <Popup content={<ArtworkDetails {...props}/>}
                       handleClose={() => setIsFullSize(false)}
                       backgroundColor="var(--white)"/> :
                <></>
            }
        </div>
    )
}