export const Menu = () => {
 return (
  <div className='home-menu container'>
                <div className='home-menu-item doodly-box box-color1'
                     onClick={() => window.location.assign(window.location.origin + "/shop")}>
                    <div className='doodle shop-doodle'/>
                    <h1>Shop</h1>
                </div>
                <div className='home-menu-item doodly-box box-color2'
                     onClick={() => window.location.assign(window.location.origin + "/portfolio")}>
                    <div className='doodle portfolio-doodle'/>
                    <h1>Portfolio</h1>
                </div>
                <div className='home-menu-item doodly-box box-color3'
                     onClick={() => window.location.assign(window.location.origin + "/contact")}>
                    <div className='doodle contact-doodle'/>
                    <h1>Contact</h1>
                </div>
                <div className='home-menu-item doodly-box box-color0'
                     onClick={() => window.location.assign(window.location.origin + "/faq")}>
                    <div className='doodle faq-doodle'/>
                    <h1>FAQ</h1>
                </div>
            </div>
 )
}