import "../common/style/portfolio.css";
import { Artwork, ArtworkProps } from "../components/Artwork";
import { ScreenModeContext } from "../stores/ScreenModeStore";
import { ScreenMode } from "../types/ScreenMode";
import { useContext, useEffect, useState } from "react";
import { PortfolioContext } from "stores/PortfolioStore";
import { FilterSelect } from "components/FilterSelect";
import { ArtworkTag } from "components/Tag";

const TAGS = Object.values(ArtworkTag);
const IMAGES_PER_LOAD = 7;
const TIME_PER_LOAD = 2000; // ms

export const Portfolio = () => {
  const imagesInfo = useContext(PortfolioContext);
  const [filters, setFilters] = useState<ArtworkTag[]>([]);
  const [loadedImages, setLoadedImages] = useState<JSX.Element[]>([]);

  useEffect(() => {
    document.title = "Portfolio | Gilliav";
  }, []);

  useEffect(() => {
    if (imagesInfo) {
      // startLazyLoad()
    }
  }, [imagesInfo]);

  const startLazyLoad = () => {
    let loadingIntervalsCounter = 0;
      const loadingIntervalsMax = Math.ceil(
        imagesInfo!.length / IMAGES_PER_LOAD
      );

      loadImages(IMAGES_PER_LOAD * loadingIntervalsCounter, IMAGES_PER_LOAD);

      loadingIntervalsCounter++;

      const interval = setInterval(() => {
        loadImages(IMAGES_PER_LOAD * loadingIntervalsCounter, IMAGES_PER_LOAD);

        loadingIntervalsCounter++;

        if (loadingIntervalsCounter >= loadingIntervalsMax) {
          clearInterval(interval);
        }
      }, TIME_PER_LOAD);

      return () => clearInterval(interval);
  }

  const loadImages = (startIndex: number, count: number) => {
    if (imagesInfo) {
      //   const loadedCount = loadedImages.length;
      const remainingImages = imagesInfo.slice(startIndex, startIndex + count);
      const newImages = remainingImages.map((imageInfo) => (
        <Artwork key={imageInfo.title} {...imageInfo} loadingStyle={startIndex === 0 ? 'eager' : 'lazy'} useProvidedThumbnail={false}/>
      ));
      setLoadedImages((prevLoadedImages) => [
        ...prevLoadedImages,
        ...newImages,
      ]);
    }
  };

  const getArtwork = (imageInfo: ArtworkProps, loadingStyle: 'eager' | 'lazy' | undefined) => {
   return (
    <Artwork key={imageInfo.title} {...imageInfo} loadingStyle={loadingStyle} useProvidedThumbnail={true}/>
   ) 
  }

  const getFilteredImages = () => {
    // Filter the images based on the selected filters
    if (!filters || filters.length === 0) {
      return loadedImages; // If no filters selected, return all images
    } else {
      return loadedImages.filter((image) =>
        filters.some((filter) => image.props.tags?.includes(filter))
      );
    }
  };

  const getAllFilteredImages = () => {
    // Filter the images based on the selected filters
    if (!filters || filters.length === 0) {
      return imagesInfo?.map(image => getArtwork(image, 'lazy')); // If no filters selected, return all images
    } else {
      return imagesInfo?.filter((image) =>
        filters.some((filter) => image.tags?.includes(filter))).map(image => getArtwork(image, 'lazy'));
    }
  };


  return (
    <div className="portfolio">
      <FilterSelect
        filters={TAGS}
        isMultiSelect={true}
        onChange={(selected) => setFilters(selected)}
      />
      <div className="grid">{getAllFilteredImages()}</div>
    </div>
  );
};
