import { ShopPolicy } from "../components/ShopPolicy"
import "common/style/faq.css"
import { useEffect } from "react"

const CONTACT_EMAIL = "hello@gilliav.com"

// TODO: change shop folicy section to be a collapsibleBox
export const FAQ = () => {

 useEffect(() => {
  document.title = "FAQ | Gilliav"
 }, [])

 return (
  <div className="container faq">
   <h1 className="main-title">Frequently Asked Questions</h1>
   <div className="doodly-big-box box-color1">
    <ShopPolicy/>
   </div>
   <h4>If you have any more questions, feel free to contact us at {CONTACT_EMAIL}.</h4>
  </div>
 )
}