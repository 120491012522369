import { useEffect, useState } from 'react';
import { Navbar } from './screens/Navbar';
import { getScreenModeBySize, ScreenModeContext } from './stores/ScreenModeStore';
import { ScreenMode } from './types/ScreenMode';
import { PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import { Firestore, getFirestore } from 'firebase/firestore';
import { ProductProps } from 'components/Product';
import { getPortfolio, getProducts } from 'handlers/DBHandler';
import { ProductContext } from 'stores/ProductsStore';
import { PortfolioContext } from 'stores/PortfolioStore';
import { ArtworkProps } from 'components/Artwork';

// console.log(process.env.REACT_APP_HI)

const CLIENT = {
  sandbox: "Afegr2nQK9JFWwOt2h0P8svqeGnoYNZTdn6rMQAL0qKvnO_C41Z-L-9n3GzkMm3GWTkKyTX2bxdIM0KU",
  production: "ARq-dxDPSMeu9xsJPg5KFebjK1zASdGbrAyXuT0kKeZaSFtqD28r_35nbzHfvtbhkijUa0VknqddnCpK"
}

const SHOP_UPDATE_TIME = 300000; // 5 minutes in ms

const CLIENT_ID = process.env.NODE_ENV == "production" ?
                  CLIENT.production : CLIENT.sandbox;

const App = () => {
  const [screenMode, setScreenMode] = useState<ScreenMode>(getScreenModeBySize);  
  const [products, setProducts] = useState<ProductProps[]>();
  const [artworks, setArtworks] = useState<ArtworkProps[]>();

  window.addEventListener('resize', () => setScreenMode(getScreenModeBySize));

  const paypalOptions = {
    "client-id": CLIENT_ID,
    intent: "capture",
    currency: "ILS"
  }

  useEffect(() => {
    // Initial update
    updateProducts();
    updatePortfolio();

    const interval = setInterval(() => updateProducts, SHOP_UPDATE_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [])

  const updateProducts = () => {
    getProducts().then(value => {
      setProducts(value)
    }
  )};

  const updatePortfolio = () => {
    getPortfolio().then(value => {
      setArtworks(value)
    }
  )};

  return (
    <ScreenModeContext.Provider value={screenMode}>
      <PayPalScriptProvider options={paypalOptions}>
        <ProductContext.Provider value={products}>
          <PortfolioContext.Provider value={artworks}>
            <Navbar/>
          </PortfolioContext.Provider>
        </ProductContext.Provider>
      </PayPalScriptProvider>
    </ScreenModeContext.Provider>
  );
}

export default App;
