import { createContext } from "react";
import { ScreenMode } from "../types/ScreenMode";

const MAX_VER_SIZE = 650;

export const getScreenModeBySize = () => {
    if (window.screen.width < MAX_VER_SIZE ||
        window.innerWidth < MAX_VER_SIZE)
        return ScreenMode.Mobile;
        return ScreenMode.Desktop;
}
    
export const ScreenModeContext = createContext<ScreenMode>(
    getScreenModeBySize()        
);