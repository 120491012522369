export enum ArtworkTag {
    Painting = "Painting",
    Sketchbook = "Sketchbook",
    Lino = "Lino",
    Digital = "Digital",
    Custom = "Custom",
    Shoes = "Shoes",
    Traditional = "Traditional",
    Album = "Album",
    Clothing = "Clothing",
    Realistic = "Realistic",
    Design = "Design",
    Cartoon = "Cartoon",
    Poster = "Poster",
    Comics = "Comics",
}