import { Info, InfoProps } from "components/Info";
import useStore from "global-hook-store";
import { useEffect } from "react";
import { cartStore } from "stores/CartStore";

const text = `A confirmation has been sent to your email. If you don't see it, please check your spam folder.${'\n'}Your order code is: `
const title = "Order Confirmation"
const subtitle = "Thank You!";

// TODO: fix css, this is off-center
export const OrderConfirmation = () => {
    const {
        state
    } = useStore(cartStore);

    const orderCode = state.orderID?.slice(0,6);

    const infoProps: InfoProps = {
        title: title,
        showTitle: false,
        className: "doodly-long-box box-color3",
        subtitle: subtitle,
        text: text + orderCode
    }

    return (
        <div className="container order-confirmation">
            <Info {...infoProps}/>
        </div>
    )
}