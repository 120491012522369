import "common/style/filter.css";

export interface FilterProps {
  title: string;
  onSelect: () => void;
  isSelected: boolean;
  onUnselect: () => void;
}

export const Filter = (props: FilterProps) => {
  const onFilterClicked = () => {
    if (!props.isSelected) props.onSelect();
    else {
      props.onUnselect();
    }
  };

  return (
    <div
      className={`filter-box ${props.isSelected ? "selected" : ""}`}
      onClick={onFilterClicked}
    >
      <h4>{props.title}</h4>
      <h4
        className="close-button"
        style={{ display: props.isSelected ? "unset" : "none" }}
        onClick={props.onUnselect}
      >
        x
      </h4>
    </div>
  );
};
